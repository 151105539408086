import { GatewayKeycloak } from '@ad-lucy/gateway-provider/keycloak';
import { getAppConfig, loadAppConfig } from '@modules/config/appConfig';
import { initDataDog } from '@modules/services/datadog/init';

async function bootstrapApp() {
  loadAppConfig();
  if (process.env.NODE_ENV === 'production' && !window.Cypress) {
    initDataDog();
  }
  // We need to init after we finish fetching the app config
  // app config has not been validated at this point, so we need to be defensive
  const keycloak = new GatewayKeycloak({
    url: getAppConfig()?.KEYCLOAK_URL,
    realm: getAppConfig()?.KEYCLOAK_REALM,
    clientId: getAppConfig()?.KEYCLOAK_CLIENT_ID,
  });

  try {
    const authenticated = await keycloak.init({
      responseMode: 'fragment',
      flow: 'standard',
      onLoad: 'check-sso',
      checkLoginIframe: !window.Cypress,
      silentCheckSsoRedirectUri: `${window.location.origin}${
        import.meta.env.BASE_URL
      }silent-check-sso.html`,
    });
    if (!authenticated) {
      return await keycloak.login();
    }
  } catch (error) {
    return keycloak.login();
  }

  if (keycloak) {
    (await import('./renderApp')).renderApp(keycloak);
  }
  return null;
}

bootstrapApp();
